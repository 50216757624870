import {
	TeamOutlined,
	BookOutlined,
	ClockCircleOutlined,
	SettingOutlined,
	FileAddOutlined,
} from '@ant-design/icons';

export const menus = [
	{
		isParent: true,
		key: '/campaign',
		path: '/campaign',
		title: 'Danh sách Campaign',
		icon: <BookOutlined />,
		nested: [],
	},
	{
		isParent: true,
		key: '/permission',
		path: '/permission',
		title: 'Danh sách phân quyền',
		icon: <TeamOutlined />,
		nested: [],
	},
	{
		isParent: true,
		key: '/campaigncourse',
		path: '/campaigncourse',
		title: 'Cấu hình liệu trình',
		icon: <SettingOutlined />,
		nested: [],
	},
	{
		isParent: true,
		title: 'Quản lý chiến dịch',
		icon: <ClockCircleOutlined />,
		nested: [
			{
				isParent: false,
				key: '/campaign-overview',
				path: '/campaign-overview',
				title: 'Tổng quan chiến dịch',
			},
			{
				isParent: false,
				key: '/salon-campaign',
				path: '/salon-campaign',
				title: 'Chiến dịch của Salon',
			},
		],
	},
	{
		isParent: true,
		title: 'Dịch vụ booking',
		icon: <SettingOutlined />,
		nested: [
			{
				isParent: false,
				key: '/danh-sach-dich-vu',
				path: '/danh-sach-dich-vu',
				title: 'Danh sách dịch vụ',
			},
			{
				isParent: false,
				key: '/danh-sach-dich-vu-cua-salon',
				path: '/danh-sach-dich-vu-cua-salon',
				title: 'Danh sách dịch vụ salon',
			},
		],
	},
];
