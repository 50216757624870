import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { fetchMenus } from 'redux/slices/menuSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

function PrivateRoute({ component: Component, ...others }) {
	const dispatch = useAppDispatch();
	const { menu } = useAppSelector((state) => state.menus);
	const [isAuth, setIsAuth] = useState(null);
	const { userInfo } = useAppSelector((state) => state.user);
	const history = useHistory();

	useEffect(() => {
		if (userInfo && dispatch) {
			if (menu === null) {
				dispatch(fetchMenus());
			} else {
				const pathName = '/' + history.location.pathname.split('/')[1];
				const link = process.env.REACT_APP_DOMAIN_PERMISSION + pathName;
				const isAuthLink = menu.find((item) => item.link === link);
				if (isAuthLink) {
					setIsAuth(true);
				} else {
					setIsAuth(false);
				}
			}
		}
	}, [userInfo, menu, dispatch, history.location]);

	return isAuth === null ? (
		<Spin spinning style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }} />
	) : isAuth ? (
		<Route
			{...others}
			render={(props) => {
				return <Component {...others} {...props} />;
			}}
		/>
	) : (
		<Redirect to={'/403'} />
	);
}
export default PrivateRoute;
