import React, { useEffect, useState } from 'react';
import './App.scss';
import config from 'configs/env';
import MainDashboard from './pages';
import { SSO } from '@30shine/sso-erp';
import axios from 'axios';
import 'antd/es/modal/style';
import 'antd/es/slider/style';

const sso = new SSO(process.env.REACT_APP_ENV || 'test');

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	const fetchCredentials = async () => {
		try {
			const credentials = await sso.ssoLogin();
			if (credentials) {
				localStorage.setItem('AccessToken', credentials.AccessToken);
				localStorage.setItem('RefreshToken', credentials.RefreshToken);
				localStorage.setItem('IdToken', credentials.IdToken);
				const request = axios.create({
					baseURL: config.base_authenticator_v2_url,
				});
				request.defaults.headers.common['Authorization'] =
					'Bearer ' + credentials.AccessToken;
				request.interceptors.response.use(
					(response) => {
						return response;
					},
					async (error) => {
						console.log(error);
						const originalRequest = error.config;
						if (error.response.status === 401) {
							let data = await sso.refreshToken();
							originalRequest.headers = {
								Authorization: 'Bearer ' + data.AccessToken,
							};
							localStorage.setItem('AccessToken', data.AccessToken);
							localStorage.setItem('RefreshToken', data.RefreshToken);
							localStorage.setItem('IdToken', data.IdToken);
							return request(originalRequest);
						}

						return Promise.reject(error);
					}
				);

				setIsAuthenticated(true);
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		fetchCredentials();
	}, []);

	return <div className="App">{isAuthenticated && <MainDashboard />}</div>;
}

export default App;
