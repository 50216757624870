import { RequestManager } from 'utils';

const getUser = (params?: any) => {
	return RequestManager.author.get('/api/user', params);
};

const getMenus = (params = null) => {
	return RequestManager.baseAuthor.get(`/api/menus`, { params });
};

export default {
	getUser,
	getMenus,
};
