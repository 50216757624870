import { RequestManager } from "utils";
const getListCampaignCourse = (params?: any) => {
	return RequestManager.campaign.post('/api/campaigncourse/gets', params);
};
const getDetailCampaignCourse = (params?: any) => {
	return RequestManager.campaign.get('/api/CampaignCourse/GetById', { params });
};
const updateCampaignCourse = (params?: any) => {
	return RequestManager.campaign.put(`/api/CampaignCourse/Update`, params);
};
const createCampaignCourse = (params?: any) => {
	return RequestManager.campaign.post('/api/CampaignCourse/Create', params);
};

export default {
    getListCampaignCourse,
    getDetailCampaignCourse,
    updateCampaignCourse,
    createCampaignCourse,
};
