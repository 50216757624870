interface EnvInterface {
    greeting: string;
    base_api_url: string;
    base_author_url: string;
    base_staff_url: string;
    base_authenticator_v2_url: string;
    base_campaign_url: string
}

const env: EnvInterface = {
    greeting: process.env.REACT_APP_GREETING,
    base_api_url: process.env.REACT_APP_BASE_API_URL,
    base_author_url: process.env.REACT_APP_BASE_AUTHOR_API_URL,
    base_staff_url: process.env.REACT_APP_BASE_STAFF_API_URL,
    base_authenticator_v2_url: process.env.REACT_APP_BASE_AUTHENTICATOR_V2_API_URL,
    base_campaign_url: process.env.REACT_APP_BASE_CAMPAIGN_API_URL
};

export default env;
