import { RequestManager } from 'utils';

const getListPermission = (params?: any) => {
	return RequestManager.permission.get('/api/permission/list-permission', {
		params,
	});
};

const getActionOfMenu = (params?: any) => {
	return RequestManager.permission.get('/api/permission/get-action-of-menu', {
		params,
	});
};

const getListMenuByPermission = (params?: any) => {
	return RequestManager.permission.get('/api/permission/get-menu-by-permission', {
		params,
	});
};

const createOrUpdatePermission = (params?: any) => {
	return RequestManager.permission.post('/api/permission/create-or-update-permission', params);
};

const updateRole = (params?: any) => {
	return RequestManager.permission.put('/api/permission/update-role', params);
};

const getPermissionInfo = (params?: any) => {
	return RequestManager.permission.get('/api/permission/get-permission-info', {
		params,
	});
};

export default {
	getListPermission,
	getActionOfMenu,
	getListMenuByPermission,
	createOrUpdatePermission,
	updateRole,
	getPermissionInfo,
};
