import { Users } from 'api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

type MenuType = {
	pageId: number;
	link: string;
	name: string;
	parentName: string;
	domain: string;
	path: string;
	description: string;
	isFavourite: boolean;
};

export const fetchMenus = createAsyncThunk('user/fetchMenus', async () => {
	const response = await Users.getMenus({
		Keyword: process.env.REACT_APP_DOMAIN_PERMISSION,
	});
	const { data, status } = response;
	if (status === 200) {
		if (data?.data.length) {
			const newData = data?.data.filter(
				(item) => item.domain === process.env.REACT_APP_DOMAIN_PERMISSION
			);
			return newData;
		}
	}
	return [];
});

const innitState = { menu: null as MenuType[] };

const menuSlice = createSlice({
	name: 'menu',
	initialState: innitState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMenus.fulfilled, (state, action) => {
			state.menu = action.payload;
		});
	},
});

const { reducer: menuReducer } = menuSlice;

export default menuReducer;
