import { RequestManager } from 'utils';

const getListServiceBySalon = (params?: any) => {
    return RequestManager.bookingCampaign.get(
        '/api/service-booking/salon/config/GetServiceBySalon', { params },
    );
};
const getListService = (params?: any) => {
    return RequestManager.bookingCampaign.get(
        '/api/service-booking/config', { params },
    );
};

const postCreateService = (params?: any) => {
    return RequestManager.bookingCampaign.post('/api/service-booking/config', params);
};

const GetDataById = (params?: any) => {
    return RequestManager.bookingCampaign.get(`/api/service-booking/config/Id?id=${params}`);
};

const UpdateService = (id?: any, data?: any) => {
    return RequestManager.bookingCampaign.put(`/api/service-booking/config?id=${id}`, data);
};

const GetDataByServiceId = (params?: any) => {
    return RequestManager.bookingCampaign.get(`/api/service-booking/config/detail?serviceId=${params}`);
};

const CreateSalonService = (params?: any) => {
    return RequestManager.bookingCampaign.post('/api/service-booking/salon/config', params);
};

const UpdateSalonService = (id?: any, data?: any) => {
    return RequestManager.bookingCampaign.put(`/api/service-booking/salon/config?id=${id}`, data);
};

const DeleteSalonService = (params?: any) => {
    return RequestManager.bookingCampaign.delete('/api/service-booking/salon/config', { params });
};
const DeleteService = (params?: any) => {
    return RequestManager.bookingCampaign.delete('/api/service-booking/config', { params });
};
const GetCategoryId = (params?: any) => {
    return RequestManager.bookingCampaign.get(`/api/service-booking/salon/config?salonId=${params}`);
};
export default {
    getListServiceBySalon,
    getListService,
    postCreateService,
    GetDataByServiceId,
    GetDataById,
    UpdateService,
    DeleteSalonService,
    UpdateSalonService,
    CreateSalonService,
    DeleteService,
    GetCategoryId,
};