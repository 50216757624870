import getInstanceAxios from '../RequestGlobalConfig';
import request from '../RequestGlobalConfig';

// const UploadData = {
//   withAuthorize: {
//     upload: function (path: string, params = {}) {
//       return request({
//         method: "POST",
//         url: process.env.REACT_APP_API_UPLOAD_CSV_DATA + path,
//         data: params,
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//     },
//   },
// };

// export default UploadData;
export default getInstanceAxios(process.env.REACT_APP_API_UPLOAD_CSV_DATA);
