import getInstanceAxios from "../RequestGlobalConfig";
import request from "../RequestGlobalConfig";

// const Author = {
//   withAuthorize: {
//     get: function( path, params = {}) {
//       return request({
//         method: "GET",
//         url: process.env.REACT_APP_BASE_AUTHENTICATOR_V2_API_URL + path,
//         params: params,
//         headers: {
//           "Content-type": "application/json"
//         }
//       });
//     },
//     post: function(path, entity = {}) {
//       return request({
//         method: "POST",
//         url: process.env.REACT_APP_BASE_AUTHENTICATOR_V2_API_URL + path,
//         responseType: "json",
//         headers: {
//           "Content-type": "application/json"
//         },
//         data: entity
//       });
//     },
//     put: function(path, entity = {}) {
//       return request({
//         method: "PUT",
//         url: process.env.REACT_APP_BASE_AUTHENTICATOR_V2_API_URL + path,
//         responseType: "json",
//         headers: {
//           "Content-type": "application/json"
//         },
//         data: entity
//       });
//     },
//     delete: function(path, entity = {}) {
//       return request({
//         method: "DELETE",
//         url: process.env.REACT_APP_BASE_AUTHENTICATOR_V2_API_URL + path,
//         responseType: "json",
//         headers: {
//           "Content-type": "application/json"
//         },
//         data: entity
//       });
//     },
//   },
//   withoutAuthorize: {
//     get: function(path, params = {}) {
//       return request({
//         method: "GET",
//         url: process.env.REACT_APP_BASE_AUTHENTICATOR_V2_API_URL + path,
//         responseType: "json",
//         params: params,
//         headers: {
//           "Content-type": "application/json"
//         }
//       });
//     },
//     post: function(path, entity = {}) {
//       return request({
//         method: "POST",
//         url: process.env.REACT_APP_BASE_AUTHENTICATOR_V2_API_URL + path,
//         responseType: "json",
//         headers: {
//           "Content-type": "application/json"
//         },
//         data: entity
//       });
//     }
//   }
// };

// export default Author;

export default getInstanceAxios(process.env.REACT_APP_BASE_AUTHENTICATOR_V2_API_URL);
