import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Campaign from "api/Campaign";

export const fetchData = createAsyncThunk("condition/fetchData", async () => {
  try {
    let dataLoad = {
      services: [],
      products: [],
      serviceCategories: [],
      productCategories: [],
      salons: [],
    };

    let responseService = await Campaign.getServiceActives();
    if (responseService.data && responseService.data.length > 0) {
      const newData = responseService.data.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
      dataLoad.services = newData;
    }

    let responseProduct = await Campaign.getProductActives();
    if (responseProduct.data && responseProduct.data.length > 0) {
      const newData = responseProduct.data.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
      dataLoad.products = newData;
    }

    let responseCategory = await Campaign.getCategories();
    if (responseCategory.data && responseCategory.data.length > 0) {
      responseCategory.data.map((item) => {
        if (item.pid === 1) {
          dataLoad.productCategories.push({ value: item.id, text: item.name });
        } else {
          dataLoad.serviceCategories.push({ value: item.id, text: item.name });
        }
      });
    }

    let responseSalons = await Campaign.getSalons();
    
    if (      
      responseSalons.data &&
      responseSalons.data.listSalon &&
      responseSalons.data.listSalon.length > 0
    ) {
      const newData = responseSalons.data.listSalon.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      dataLoad.salons = newData;
    }

    return dataLoad;
  } catch (error) {
  } finally {
  }
  return undefined;
});

const innitState = {
  dataInfo: {
    services: [],
    products: [],
    serviceCategories: [],
    productCategories: [],
    salons: [],
  },
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState: innitState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.dataInfo = action.payload;
    });
  },
});

const { reducer: campaignReducer } = campaignSlice;
export default campaignReducer;
