import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Redirect, withRouter } from 'react-router';
import Loading from '../components/DashboardLayout/Loading';
import PrivateRoute from './PrivateRoute';
import Page404 from '../pages/Errors/404';
import Page403 from '../pages/Errors/403';
import Page500 from '../pages/Errors/500';
const DefaultDashboard = React.lazy(() => import('../pages/DefaultDashboard'));
const ListCamPaign = React.lazy(() => import('../pages/Campaign'));
const CreateCampaign = React.lazy(() => import('../pages/Campaign/Create'));
const UpdateCampaign = React.lazy(() => import('../pages/Campaign/Update'));

const PagePermission = React.lazy(() => import('pages/Permission'));
const CreatePermission = React.lazy(() => import('pages/Permission/Create'));
const UpdatePermission = React.lazy(() => import('pages/Permission/Update'));

const ListCampaignCourse = React.lazy(() => import('pages/CampaignCourse'));
const CreateCampaignCourse = React.lazy(() => import('pages/CampaignCourse/Create'));
const UpdateCampaignCourse = React.lazy(() => import('pages/CampaignCourse/Update'));
const CampaignOverview = React.lazy(() => import('../pages/CampaignManager/CampaignOverview'));
const SalonCampaign = React.lazy(() => import('../pages/CampaignManager/SalonCampaign'));
const CreateCampaignCSKH = React.lazy(
	() => import('../pages/CampaignManager/CampaignOverview/CreateNewCampaignCSKH')
);
const UpdateCampaignCSKH = React.lazy(
	() => import('../pages/CampaignManager/CampaignOverview/UpdateCampaignCSKH')
);
const ViewCampaignCSKH = React.lazy(
	() => import('../pages/CampaignManager/CampaignOverview/ViewCampaignCSKH')
);

const ViewDetail = React.lazy(() => import('../pages/CampaignManager/SalonCampaign/ViewDetail'));

const DataRecovery = React.lazy(() => import('../pages/CampaignManager/SalonCampaign/CusCareRecv'));

const StaffViewDetail = React.lazy(() => import('../pages/CampaignManager/SalonCampaign/StaffViewDetail'));

const DataDevide = React.lazy(
	() => import('../pages/CampaignManager/SalonCampaign/CusCareProgDivide')
);
const ListService = React.lazy(() => import('pages/configServiceBooking/listService'));
const ListServiceBySalon = React.lazy(
	() => import('pages/configServiceBooking/listServiceBySalon')
);
const CreateService = React.lazy(() => import('pages/configServiceBooking/listService/components/create'));
const UpdateService = React.lazy(() => import('pages/configServiceBooking/listService/components/update'));

function MainRouter() {
	return (
		<div>
			<Suspense fallback={<Loading />}>
				<Switch>
					<Route exact path="/" render={() => <Redirect to="/campaign" />} />
					<PrivateRoute exact path="/campaign" component={ListCamPaign} />
					<PrivateRoute path="/create-campaign" component={CreateCampaign} />
					<PrivateRoute path="/update-campaign/:id" component={UpdateCampaign} />
					<PrivateRoute path="/permission" component={PagePermission} />
					<PrivateRoute path="/create-permission" component={CreatePermission} />
					<PrivateRoute path="/update-permission/:id" component={UpdatePermission} />
					<PrivateRoute exact path="/campaigncourse" component={ListCampaignCourse} />
					<PrivateRoute path="/create-campaigncourse" component={CreateCampaignCourse} />
					<PrivateRoute
						path="/update-campaigncourse/:id"
						component={UpdateCampaignCourse}
					/>

					<PrivateRoute path="/campaign-overview" component={CampaignOverview} />
					<PrivateRoute path="/salon-campaign" component={SalonCampaign} />
					<PrivateRoute path="/create-campaign-cskh" component={CreateCampaignCSKH} />
					<PrivateRoute path="/update-campaign-cskh/:id" component={UpdateCampaignCSKH} />
					<PrivateRoute path="/view-campaign-cskh/:id" component={ViewCampaignCSKH} />
					<PrivateRoute path="/view-detail-campaign-cskh/:id" component={ViewDetail} />
					<PrivateRoute path="/cskh-data-recovery/:id" component={DataRecovery} />
					<PrivateRoute path="/cskh-data-devide/:id" component={DataDevide} />

					<Route path="/danh-sach-dich-vu" component={ListService} />
					<Route path="/staff-view-detail/cusCareProgId=:cusCareProgId&staffId=:staffId" component={StaffViewDetail} />
					<Route
						path="/danh-sach-dich-vu-cua-salon"
						component={ListServiceBySalon}
					/>
					<Route path="/create-service" component={CreateService} />
					<Route path="/update-service/:id" component={UpdateService} />
					<Route path="/403" component={Page403} />
					<Route path="/500" component={Page500} />
					<Route path="*" component={Page404} />
				</Switch>
			</Suspense>
		</div>
	);
}

export default withRouter(MainRouter);
