import React, { useCallback, useEffect, useState } from 'react';
import { Layout, Dropdown, Button, Menu, Space, Divider, Input } from 'antd';
import { CaretDownOutlined, LogoutOutlined, SearchOutlined } from '@ant-design/icons';
import './DashboardLayout.scss';
import MainRouter from '../routes/MainRouter';
import { BrowserRouter as Router } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import personIcon from '../assets/images/person.png';
import { withReducer } from 'hoc';
import { orEmpty, orNull } from 'utils/Selector';
import { useMediaQuery } from 'react-responsive';
import { SSO } from '@30shine/sso-erp';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { fetchUser } from 'redux/slices/userSlice';
import MainMenu from 'components/DashboardLayout/MainMenu';

const sso = new SSO(process.env.REACT_APP_ENV || 'test');
const { Header, Sider, Content } = Layout;

function DashboardLayout() {
	const isMinPC = useMediaQuery({
		query: '(max-width: 1450px)',
	});
	const [collapsed, setCollapsed] = useState(isMinPC);
	const { userInfo } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();

	const toggleMenu = () => {
		setCollapsed(!collapsed);
	};

	const logOut = async () => {
		sso.ssoLogout();
	};

	const menu = (
		<Menu>
			<Menu.Item key="1" icon={<LogoutOutlined />} onClick={logOut}>
				Đăng xuất
			</Menu.Item>
		</Menu>
	);

	useEffect(() => {
		dispatch(fetchUser());
	}, []);

	return (
		<Router>
			<Layout id="erp-v2">
				<Header className="header">
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<div className="logo">
							<a href="/">
								<img src={logo} alt="" />
							</a>
						</div>
					</div>
					<Dropdown overlay={menu} className="account-dropdown">
						<Button className="account-button">
							<img src={personIcon} alt="" />
							<strong style={{ marginLeft: 10 }}>
								Xin chào, {userInfo?.fullName}
							</strong>
							<CaretDownOutlined color="white" />
						</Button>
					</Dropdown>
				</Header>
				<Layout className="site-layout-true">
					<Sider
						className="sider-layout-sidebar"
						trigger={null}
						collapsible
						collapsed={collapsed}
					>
						<MainMenu />
					</Sider>
					<Content className="site-layout-background content">
						<MainRouter />
					</Content>
				</Layout>
			</Layout>
		</Router>
	);
}

export default DashboardLayout;
