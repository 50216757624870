import config from 'configs/env';
import axios, { AxiosResponse } from 'axios';
import { SSO } from '@30shine/sso-erp';

const sso = new SSO(process.env.REACT_APP_ENV || 'test');

export default function getInstanceAxios(baseAPI: string) {
	const instance = axios.create({
		baseURL: baseAPI,
	});
	instance.interceptors.request.use(
		function (config) {
			const AccessToken = localStorage.getItem('AccessToken');
			config.headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: AccessToken ? 'Bearer ' + AccessToken : '',
			};
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);
	instance.interceptors.response.use(
		function (response: AxiosResponse) {
			try {
				if (response.status !== 200) return Promise.reject(response);
				return response;
			} catch (error) {
				return Promise.reject(error);
			}
		},
		async (error) => {
			if (error.response.status === 401) {
				localStorage.clear();
				try {
					let data = await sso.refreshToken();
					if (data?.AccessToken) {
						localStorage.setItem('AccessToken', data.AccessToken);
						localStorage.setItem('RefreshToken', data.RefreshToken);
						localStorage.setItem('IdToken', data.IdToken);
					}
				} catch (error) {
					sso.ssoLogout();
				}
			}
			return Promise.reject(error);
		}
	);
	return instance;
}
